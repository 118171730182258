/*
 * This is the only way in which it is possible to suppress this lint rule.
 * When referencing the Nav4NotificationsData component directly we introduce a cyclic dependency
 * between @atlassian/jira-atlassian-navigation and @atlassian/jira-atlassian-navigation-nav4
 * (and cyclic dependency errors be suppressed to pass the build).
 */
/* eslint-disable @atlassian/relay/must-colocate-fragment-spreads */
/** @jsx jsx */
import React, { useMemo, useCallback } from 'react';
import { jsx } from '@compiled/react';
import { type PreloadedQuery, graphql, useLazyLoadQuery, usePreloadedQuery } from 'react-relay';
import { Nav4OnboardingComponentNames } from '@atlassian/jira-atlassian-onboarding-nav4/src/types.tsx';
import { OnboardingSpotlightMaybe } from '@atlassian/jira-atlassian-onboarding-nav4/src/ui/onboarding-spotlight-maybe/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { topNav4UIStateResource } from '@atlassian/jira-navigation-apps-resources/src/services/top-nav/index.tsx';
import { V4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/nav-state.tsx';
import { TOP } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/navigation-container.tsx';
import { GLOBAL } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/navigation-layer.tsx';
import { getSideNavToggleButtonAnalyticEventPayload } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import { getNav4Rollout } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-nav4-rollout/index.tsx';
import { IS_LEFT_SIDEBAR_COLLAPSED_DEFAULT } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/constants/index.tsx';
import {
	ContextualAnalyticsData,
	fireUIAnalytics,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import { useRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import type { AtlaskitAtlassianNavigationNav4Query } from '@atlassian/jira-relay/src/__generated__/AtlaskitAtlassianNavigationNav4Query.graphql';
import type { AtlaskitAtlassianNavigationNav4UIQuery } from '@atlassian/jira-relay/src/__generated__/AtlaskitAtlassianNavigationNav4UIQuery.graphql';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import {
	HomeActions,
	CommonActions,
	UserActions,
	SideNavToggleButton,
	type SideNavVisibilityChangeAnalyticsAttributes,
} from '@atlassian/navigation-system';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import {
	PACKAGE_NAME,
	TEAM_NAME,
} from '@atlassian/jira-atlassian-navigation-nav4-common/src/common/constants.tsx';
import { messages } from './messages.tsx';
import { Nav4Profile } from './profile/Nav4Profile.tsx';
import type { AtlaskitAtlassianNavigationNav4Props } from './types.tsx';

const Nav4TopNavigation = componentWithFG(
	'blu-5614-topnav-sidebar-toggle-button-for-ssr',
	TopNavNew,
	TopNavNoUserPrefs,
);

export function AtlaskitAtlassianNavigationNav4(props: AtlaskitAtlassianNavigationNav4Props) {
	return (
		<UFOSegment name="atlaskit-atlassian-navigation-nav4">
			<JSErrorBoundary
				id="AtlaskitAtlassianNavigationNav4"
				packageName={PACKAGE_NAME}
				teamName={TEAM_NAME}
			>
				<Nav4TopNavigation {...props} />
			</JSErrorBoundary>
		</UFOSegment>
	);
}

function TopNavNew(props: AtlaskitAtlassianNavigationNav4Props) {
	const isAnonymous = useIsAnonymous();

	if (isAnonymous) {
		return <TopNavNoUserPrefs {...props} />;
	}

	// This intermediate step is to avoid fetching a user-preference-resource for an anonymous user.
	// Because hooks cannot be conditionally called, we create a new component.
	return <TopNavNewUserPrefs {...props} />;
}

function TopNavNewUserPrefs(props: AtlaskitAtlassianNavigationNav4Props) {
	const { queryReference } = useRelayResource(topNav4UIStateResource);

	// This intermediate step is to avoid using a null queryReference.
	// Maybe this should be a skeleton? Or is that taken care of by the page-container-v2?
	return queryReference ? (
		<TopNavNewCommonWrapper queryReference={queryReference} {...props} />
	) : null;
}

type TopNavProps = AtlaskitAtlassianNavigationNav4Props & {
	queryReference: PreloadedQuery<AtlaskitAtlassianNavigationNav4UIQuery>;
};

function TopNavNewCommonWrapper(props: TopNavProps) {
	const { queryReference } = props;

	const data = usePreloadedQuery<AtlaskitAtlassianNavigationNav4UIQuery>(
		graphql`
			query AtlaskitAtlassianNavigationNav4UIQuery(
				$cloudId: ID!
				$includeExtraMenuItems: Boolean!
				$isAnonymous: Boolean!
			) {
				jira @required(action: THROW) {
					navigationUIState(cloudId: $cloudId) {
						isLeftSidebarCollapsed @required(action: THROW)
					}
				}
				...Nav4Profile
				...Nav4NotificationsData @skip(if: $isAnonymous)
			}
		`,
		queryReference,
	);

	const defaultCollapsed =
		data?.jira?.navigationUIState?.isLeftSidebarCollapsed ?? IS_LEFT_SIDEBAR_COLLAPSED_DEFAULT;

	// @ts-expect-error - The code works, the query reference was generated by the relay compiler.
	return <TopNavCommon {...props} defaultCollapsed={defaultCollapsed} queryReference={data} />;
}

function TopNavNoUserPrefs(props: AtlaskitAtlassianNavigationNav4Props) {
	const data = useLazyLoadQuery<AtlaskitAtlassianNavigationNav4Query>(
		graphql`
			query AtlaskitAtlassianNavigationNav4Query($includeExtraMenuItems: Boolean!) {
				...Nav4Profile
			}
		`,
		{
			includeExtraMenuItems: expVal(
				'jira_nav4_hot_113350_account_menu_experiment',
				'cohort',
				false,
			),
		},
	);

	// @ts-expect-error - The code works, the query reference was generated by the relay compiler.
	return <TopNavCommon {...props} queryReference={data} />;
}

type TopNavCommonProps = AtlaskitAtlassianNavigationNav4Props & {
	queryReference: AtlaskitAtlassianNavigationNav4UIQuery;
};

function TopNavCommon(props: TopNavCommonProps) {
	const {
		defaultCollapsed = false,
		hideSideNavToggleButton,
		queryReference,
		renderCreate: Create,
		renderHelp: Help,
		renderAppSwitcher: AppSwitcher,
		renderNotifications: Notifications,
		renderNav4Notifications: Nav4NotificationsData,
		renderConversationAssistant: ConversationAssistant,
		renderProductHome: ProductHome,
		renderProfile: Profile,
		renderSettings: Settings,
		renderSearch: Search,
		renderEditionAwareness: EditionAwareness,
		renderSignIn: SignIn,
	} = props;
	const isAnonymous = useIsAnonymous();
	const { formatMessage } = useIntl();

	const attributes = useMemo(
		() => ({
			navigationLayer: GLOBAL,
			navigationContainer: TOP,
			navState: V4,
			stage: getNav4Rollout().stage,
		}),
		[],
	);

	const onClickSideNavToggleButton = useCallback(
		(
			_: React.MouseEvent<HTMLElement>,
			analyticsEvent: UIAnalyticsEvent,
			_attributes: SideNavVisibilityChangeAnalyticsAttributes | undefined,
		) => {
			const payload = getSideNavToggleButtonAnalyticEventPayload(
				_attributes?.isSideNavVisible,
				getNav4Rollout().stage,
			);

			fireUIAnalytics(analyticsEvent, payload);
		},
		[],
	);

	const renderNotificationsForNavVersion = useCallback(() => {
		if (Notifications !== undefined) {
			if (expVal('blu-6229-nav4-top-nav-notifications-perf', 'cohort', false)) {
				// @ts-expect-error - The code works, the query reference was generated by the relay compiler.
				return <Nav4NotificationsData queryReference={queryReference} />;
			}
			return <Notifications />;
		}
		return null;
	}, [Nav4NotificationsData, Notifications, queryReference]);

	return (
		<ContextualAnalyticsData attributes={attributes}>
			<HomeActions>
				{hideSideNavToggleButton ? null : (
					<OnboardingSpotlightMaybe
						spotlight={Nav4OnboardingComponentNames.JIRA_SIDEBAR_TOGGLE_SPOTLIGHT}
					>
						<SideNavToggleButton
							defaultCollapsed={defaultCollapsed}
							onClick={onClickSideNavToggleButton}
							collapseLabel={formatMessage(messages.sideNavToggleButtonCollapseLabel)}
							expandLabel={formatMessage(messages.sideNavToggleButtonExpandLabel)}
						/>
					</OnboardingSpotlightMaybe>
				)}
				{AppSwitcher && <AppSwitcher />}
				{ProductHome && <ProductHome />}
			</HomeActions>
			<CommonActions>
				{Search && <Search />}
				{Create && <Create />}
			</CommonActions>
			<UserActions>
				{EditionAwareness && <EditionAwareness />}
				{!isAnonymous && ConversationAssistant && <ConversationAssistant />}
				{!isAnonymous && renderNotificationsForNavVersion()}
				{Help && <Help />}
				{Settings && <Settings />}
				{Profile && !expVal('jira_nav4_hot_113350_account_menu_experiment', 'cohort', false) ? (
					<Profile />
				) : (
					// @ts-expect-error - The code works, the query reference was generated by the relay compiler.
					<Nav4Profile queryReference={queryReference} />
				)}
				{SignIn && !expVal('jira_nav4_hot_113350_account_menu_experiment', 'cohort', false) && (
					<SignIn />
				)}
			</UserActions>
		</ContextualAnalyticsData>
	);
}
