import type { IrremovableFieldsConfig } from '@atlassian/jira-jql-builder-basic/src/utils/irremovable-fields/index.tsx';
import type { GetFiltersProps } from './types.tsx';

export const RESOURCE_TYPE_NAME = 'projectSummaryFilterFieldResource';
export const RESOURCE_TYPE_FILTER_FIELD = 'RESOURCE_TYPE_FILTER_FIELD';

export const REQUEST_TYPE_FILTER_TYPE = 'com.atlassian.servicedesk:vp-origin';
export const REQUEST_TYPE_FILTER_SEARCH_TEMPLATE = 'com.atlassian.servicedesk:vp-origin-searcher';

export const PRIORITY_FILTER_TYPE = 'priority';
export const PRIORITY_FILTER_SEARCH_TEMPLATE = 'priority';

export const CREATED_FILTER_TYPE = 'created';
export const CREATED_FILTER_SEARCH_TEMPLATE = 'created';

export const getFilterFields = ({
	requestTypeField,
	priorityField,
	createdField,
}: GetFiltersProps): IrremovableFieldsConfig => [
	...(requestTypeField ? [requestTypeField] : []),
	'status',
	'assignee',
	...(priorityField ? [priorityField] : []),
	...(createdField ? [createdField] : []),
];
