/**
 * @generated SignedSource<<500801af80a84a11e821bed01586a356>>
 * @relayHash ab50a7efb32635213e5039c470a5cee7
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 360e64e22cdf65b74ed19db0c230512c1c4f566ca5bb4d40918481f002919751

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessBoardQuery } from './src_jiraBusinessBoardQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessBoardQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "360e64e22cdf65b74ed19db0c230512c1c4f566ca5bb4d40918481f002919751",
    "metadata": {},
    "name": "src_jiraBusinessBoardQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
