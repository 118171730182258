/**
 * @generated SignedSource<<5af5fded2340c144d3c73aef2fc116ef>>
 * @relayHash 987d9713eab23a4f0e1155326dac7ecf
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 3d1c9377b1c42a4d3cce3ad6dfb34529e73944bc3396c5ac8cda361cddf86ce6

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessBoardEmbedQuery } from './src_jiraBusinessBoardEmbedQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessBoardEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "3d1c9377b1c42a4d3cce3ad6dfb34529e73944bc3396c5ac8cda361cddf86ce6",
    "metadata": {},
    "name": "src_jiraBusinessBoardEmbedQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
