import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { useCallback } from 'react';

import { type POEventPayload } from './types';

export type PostOfficeAnalyticsHookScope = {
	fireAnalyticsEvent: (event: POEventPayload, attributes?: Record<string, unknown>) => void;
};

export const ANALYTICS_CHANNEL = 'postOffice';

/**
 * Hook to fire track and ui analytics events for Post Office. All events will be fired with the channel `postOffice`. PlacementId will be added to attributes via PostOfficeAnalyticsContext.
 * @returns {Object} - Object containing `fireAnalyticsEvent` function.
 */
export const useFirePostOfficeAnalyticsEvents = (): PostOfficeAnalyticsHookScope => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireAnalyticsEvent = useCallback(
		<Attributes extends Record<string, unknown>>(
			eventPayload: POEventPayload,
			attributes?: Attributes,
		) => {
			createAnalyticsEvent({
				...event,
				side: 'client',
				attributes: {
					...eventPayload.attributes,
					...attributes,
				},
			}).fire(ANALYTICS_CHANNEL);
		},
		[createAnalyticsEvent],
	);

	return {
		fireAnalyticsEvent,
	};
};
