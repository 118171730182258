import { isHttpClientErrorResponse } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { IrremovableFieldsConfig } from '@atlassian/jira-jql-builder-basic/src/utils/irremovable-fields/index.tsx';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import {
	createResource,
	useResource,
	type RouterContext,
	type Match,
	type Query,
} from '@atlassian/react-resource-router';
import { getFilterFields, RESOURCE_TYPE_FILTER_FIELD } from './constants.tsx';
import { QUERY } from './gql.tsx';
import type { FilterFieldResponse, FiltersProps } from './types.tsx';
import { reportError, createFilterFieldsGetter } from './utils.tsx';

const fetchFields = ['request type', 'priority', 'created'];

const fetchFilterFields = async (
	cloudId: string,
	jqlContext: string,
): Promise<(props: FiltersProps) => IrremovableFieldsConfig> => {
	try {
		const responses = await Promise.all(
			fetchFields.map((searchString) =>
				performPostRequest<FilterFieldResponse>('/gateway/api/graphql', {
					body: JSON.stringify({
						query: QUERY,
						variables: {
							cloudId,
							contextFieldsFilter: {
								searchString,
							},
							jqlContext,
						},
					}),
					headers: {
						'content-type': 'application/json',
						'X-ExperimentalApi': 'JiraJqlBuilder',
					},
				}),
			),
		);

		return createFilterFieldsGetter(responses);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		if (!isHttpClientErrorResponse(error)) {
			reportError(error);
		}

		return ({ requestTypeField }: FiltersProps) => getFilterFields({ requestTypeField });
	}
};

const getProjectKeyOrId = (match: Match, query: Query) => {
	const { projectKey, projectId } = getProjectKeyId(match, query);
	return projectKey || projectId;
};

const filterFieldsResource = createResource({
	type: RESOURCE_TYPE_FILTER_FIELD,
	getKey: ({ match, query }: RouterContext) =>
		RESOURCE_TYPE_FILTER_FIELD + getProjectKeyOrId(match, query),
	getData: ({ match, query }, { tenantContext: { cloudId } }) =>
		fetchFilterFields(cloudId, `project = '${getProjectKeyOrId(match, query)}'`),
	maxAge: Infinity,
});

export const getProjectSummaryFilterFieldsResource = () =>
	resourceWithCondition2(() => fg('jsm_project_summary_filters'), filterFieldsResource);

export const useProjectSummaryFilterResource = () => useResource(filterFieldsResource);
