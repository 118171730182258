import React, { useState } from 'react';
import ListBulletedIcon from '@atlaskit/icon/core/list-bulleted';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { ButtonItem } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import mergeRefs from '@atlassian/jira-merge-refs/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { DisplaySettingsScope } from '@atlassian/jira-navigation-apps-sidebar-nav4-display-settings/src/ui/display-settings-provider/constants.tsx';
import { Skeleton } from '../skeleton/index.tsx';
import messages from './messages.tsx';
import type { ChangeViewMenuItemProps } from './types.tsx';
import { ViewOptionsMenuPopupContentEntrypoint } from './view-options/entrypoint.tsx';

export function ChangeViewMenuItem({ onOpen, onChange }: ChangeViewMenuItemProps) {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);

	const onChangeViewMenuItemClick = () => {
		if (!isOpen && onOpen) {
			onOpen();
		}
		setIsOpen(!isOpen);
	};

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		ViewOptionsMenuPopupContentEntrypoint,
		{
			scope: DisplaySettingsScope.PROJECTS,
			onChange,
		},
	);

	const entrypointRef = useEntryPointButtonTrigger(entryPointActions);

	return (
		<Popup
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			content={() => (
				<JiraEntryPointContainer
					entryPointReferenceSubject={entryPointReferenceSubject}
					id="display-settings-menu-popup-content"
					packageName="jira-navigation-apps-sidebar-nav4-display-settings"
					fallback={<Skeleton />}
					runtimeProps={{}}
				/>
			)}
			placement="right-start"
			messageId="navigation-apps-sidebar-nav4-display-settings.ui.change-view-menu-item.popup"
			messageType="transactional"
			shouldRenderToParent
			trigger={({ ref, ...triggerProps }) => (
				<ButtonItem
					{...triggerProps}
					ref={mergeRefs(ref, entrypointRef)}
					isSelected={isOpen}
					iconBefore={
						<ListBulletedIcon
							label={formatMessage(messages.listBulletedIcon)}
							spacing="spacious"
							color={token('color.icon')}
						/>
					}
					iconAfter={<ChevronRightIcon label={formatMessage(messages.chevronRightIcon)} />}
					onClick={onChangeViewMenuItemClick}
					aria-label={formatMessage(messages.changeViewMenuItemLabel)}
					aria-expanded={isOpen}
					aria-haspopup="true"
				>
					{formatMessage(messages.changeViewMenuItemLabel)}
				</ButtonItem>
			)}
		/>
	);
}
