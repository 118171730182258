import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { IrremovableFieldsConfig } from '@atlassian/jira-jql-builder-basic/src/utils/irremovable-fields/index.tsx';
import { TEAM_NAME, PACKAGE_NAME } from '../../common/constants.tsx';
import {
	getFilterFields,
	RESOURCE_TYPE_NAME,
	REQUEST_TYPE_FILTER_TYPE,
	REQUEST_TYPE_FILTER_SEARCH_TEMPLATE,
	PRIORITY_FILTER_TYPE,
	PRIORITY_FILTER_SEARCH_TEMPLATE,
	CREATED_FILTER_TYPE,
	CREATED_FILTER_SEARCH_TEMPLATE,
} from './constants.tsx';
import type { FilterFieldResponse, FilterFieldResponseData, FiltersProps } from './types.tsx';

export const reportError = (error: Error) =>
	fireErrorAnalytics({
		error,
		sendToPrivacyUnsafeSplunk: true,
		meta: {
			id: RESOURCE_TYPE_NAME,
			packageName: PACKAGE_NAME,
			teamName: TEAM_NAME,
		},
	});

const tryGetField = ({
	data,
	name,
	type,
	searchTemplate,
	displayName,
}: {
	data: FilterFieldResponseData;
	name: string;
	type: string;
	searchTemplate: string;
	displayName?: string;
}) => {
	const edges = data?.jira?.jqlBuilder?.fields?.edges;

	const field = edges?.find(
		(edge) => edge?.node?.type === type && edge?.node?.searchTemplate?.key === searchTemplate,
	)?.node;

	if (!field) {
		reportError(new Error(`Unable to prefetch ${name}`));
	}

	return field
		? {
				...field,
				searchTemplate: field.searchTemplate.key,
				fieldType: field.type,
				displayName: displayName ?? field.displayName,
			}
		: undefined;
};

export const createFilterFieldsGetter =
	(responses: FilterFieldResponse[]): ((props: FiltersProps) => IrremovableFieldsConfig) =>
	({
		requestTypeField,
		requestTypeDisplayName,
		priorityDisplayName,
		createdDisplayName,
	}: FiltersProps) =>
		getFilterFields({
			requestTypeField:
				requestTypeField ??
				tryGetField({
					data: responses[0].data,
					name: 'request type',
					type: REQUEST_TYPE_FILTER_TYPE,
					searchTemplate: REQUEST_TYPE_FILTER_SEARCH_TEMPLATE,
					displayName: requestTypeDisplayName,
				}),
			priorityField: tryGetField({
				data: responses[1].data,
				name: 'priority',
				type: PRIORITY_FILTER_TYPE,
				searchTemplate: PRIORITY_FILTER_SEARCH_TEMPLATE,
				displayName: priorityDisplayName,
			}),
			createdField: tryGetField({
				data: responses[2].data,
				name: 'created',
				type: CREATED_FILTER_TYPE,
				searchTemplate: CREATED_FILTER_SEARCH_TEMPLATE,
				displayName: createdDisplayName,
			}),
		});
