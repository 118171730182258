import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { Bleed } from '@atlaskit/primitives';
import { CustomOption } from '@atlaskit/smart-user-picker/option';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import TwoPeopleWithPlusIllustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/other/assets/team-two-people-with-plus.svg';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { CREATE_TEAM_ID } from '../../../common/constants.tsx';
import messages from '../../../messages.tsx';
import { useTeamPermissions } from '../../../services/use-team-permissions/index.tsx';
import CreateTeamAvatar from './assets/create-team-avatar.svg';

type CreateTeamOptionProps = {
	onClick: () => void;
	isFocused: boolean;
};

export function CreateTeamOption({ onClick, isFocused }: CreateTeamOptionProps) {
	const { formatMessage } = useIntl();
	const formattedCreateTeamText = formatMessage(messages.createTeamButtonText);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleCreateTeamOptionClick = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'createTeamInline', {
			teamsFieldEmptyState: false,
		});
		onClick();
	}, [onClick, createAnalyticsEvent]);

	const { canCreateTeams } = useTeamPermissions();

	return canCreateTeams ? (
		// eslint-disable-next-line jira/restricted/react-suspense
		<React.Suspense fallback={null}>
			<Bleed inline="space.200" block="space.100">
				<CreateButtonBorder />
				<Container
					onClick={handleCreateTeamOptionClick}
					isFocused={isFocused}
					data-testid="issue-field-team.ui.edit-mode.create-team-option.create-team-inline-option"
				>
					<OptionDataWrapper>
						<CustomOption
							isSelected={false}
							data={{
								id: CREATE_TEAM_ID,
								name: formattedCreateTeamText,
								type: 'custom',
								avatarUrl: fg('move_issue_view_assets_into_central_location')
									? TwoPeopleWithPlusIllustration
									: CreateTeamAvatar,
							}}
						/>
					</OptionDataWrapper>
				</Container>
			</Bleed>
		</React.Suspense>
	) : null;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CreateButtonBorder = styled.div({
	borderTop: `1px solid ${token('color.border')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ isFocused: boolean }>({
	marginTop: token('space.025'),
	width: '100%',
	height: '100%',
	'&:hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered'),
		boxShadow: `inset 2px 0px 0px ${token('color.border.selected')}`,
	},
	// isFocused
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isFocused }) =>
		isFocused ? token('color.background.neutral.subtle.hovered') : 'transparent',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	boxShadow: ({ isFocused }) =>
		isFocused ? `inset 2px 0px 0px ${token('color.border.selected')}` : 'none',
});

// Matching spacing of other options
// eslint-disable-next-line @atlaskit/design-system/use-primitives, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionDataWrapper = styled.div({
	paddingTop: token('space.075'),
	paddingRight: token('space.150'),
	paddingBottom: token('space.075'),
	paddingLeft: token('space.150'),
});
