import { fg } from '@atlassian/jira-feature-gating';

export const getSystemNavigationPaths = () => ({
	generalConfiguration: '/jira/settings/system/general-configuration',
	darkFeatures: '/secure/admin/SiteDarkFeatures!default.jspa',
	issueCollectors: '/secure/admin/ViewGlobalCollectors!default.jspa',
	globalAutomation: '/jira/settings/automation',

	// troubleshooting
	troubleshootingAuditLog: '/auditing/view',
	troubleshootingPluginViewer: '/plugins/servlet/depview/plugins',
	troubleshootingModulesViewer: '/plugins/servlet/depview/modules',
	troubleshootingSystemInfo: '/secure/admin/ViewSystemInfo.jspa',
	troubleshootingIntegrityChecker: '/secure/admin/IntegrityChecker!default.jspa',
	troubleshootingLoggingProfiling: '/secure/admin/ViewLogging.jspa',
	troubleshootingSchedulerDetails: '/secure/admin/SchedulerAdmin.jspa',
	troubleshootingPerformanceAndScale: '/jira/settings/system/performance/overview',

	// security
	securityProjectRoles: '/secure/project/ViewProjectRoles.jspa',
	securityGlobalPermissions: fg('olympus-global-permissions-fe-modernisation')
		? '/secure/admin/global-permissions'
		: '/secure/admin/GlobalPermissions!default.jspa',

	// user interface
	userInterfacesUserDefaults: '/secure/admin/ViewUserDefaultSettings.jspa',
	userInterfacesEditDefaultDashboard: '/secure/admin/EditDefaultDashboard!default.jspa',
	userInterfacesLookAndFeel: '/secure/admin/LookAndFeel!default.jspa',
	userInterfacesPasAdmin: '/plugins/servlet/pas/admin',
	userInterfacesSiteAdmin: '/jira/settings/system/ui/announcement-banner',

	// import and export
	importSectionBackupManager: '/secure/admin/CloudExport.jspa',
	importSectionExternalImport: '/secure/admin/ExternalImport1.jspa',
	importSectionExternalImportNew: '/jira/settings/system/external-import',
	importSectionCloudImport: '/secure/admin/CloudImport!start.jspa?source=import_cloud',
	importSectionServerImport: '/secure/admin/CloudImport!start.jspa?source=import_server',

	// mail
	mailSectionGlobalMailSettings: '/secure/admin/GlobalMailSettings.jspa',
	mailSectionOutgoingMail: '/secure/admin/OutgoingMailServers.jspa',
	mailSectionIncomingMail: '/secure/admin/IncomingMailServers.jspa',
	mailSectionSendMail: '/secure/admin/SendBulkMail!default.jspa',

	// admin
	adminHelperPermissionHelper: '/secure/admin/PermissionHelperAdmin.jspa',
	adminHelperEmailNotificationsLogHelper: '/jira/settings/system/notification-logs',

	// shared
	sharedSectionManageFilters: '/secure/admin/filters/ViewSharedFilters.jspa',
	sharedManageDashboards: '/secure/admin/dashboards/ViewSharedDashboards.jspa',

	// advanced
	advancedSectionAttachments: '/secure/admin/ViewAttachmentSettings.jspa',
	advancedSectionEvents: '/secure/admin/ListEventTypes.jspa',
	advancedSectionWebHooks: '/plugins/servlet/webhooks',
	advancedSectionServices: '/secure/admin/ViewServices!default.jspa',
	advancedLexoRankManagement: '/secure/GHLexoRankManage.jspa?decorator=admin',
});

const getSystemNavPaths = () => {
	return [
		'/jira/settings/system/general-configuration',
		'/secure/admin/SiteDarkFeatures',
		'/secure/admin/ViewGlobalCollectors',
		'/jira/settings/automation',
		'/auditing/view',
		'/secure/project/ViewProjectRoles',
		'/secure/admin/global-permissions',
		'/secure/admin/GlobalPermissions',
		'/secure/admin/ViewUserDefaultSettings',
		'/secure/admin/EditDefaultDashboard',
		'/secure/admin/LookAndFeel',
		'/jira/settings/system/ui/announcement-banner',
		'/secure/admin/CloudExport',
		'/jira/settings/system/external-import',
		'/secure/admin/CloudImport',
		'/secure/admin/GlobalMailSettings',
		'/secure/admin/global-mail-settings',
		'/secure/admin/OutgoingMailServers',
		'/secure/admin/IncomingMailServers',
		'/secure/admin/SendBulkMail',
		'/secure/admin/PermissionHelperAdmin',
		'/jira/settings/system/notification-logs',
		'/secure/admin/filters/ViewSharedFilters',
		'/secure/admin/dashboards/ViewSharedDashboards',
		'/secure/admin/ViewAttachmentSettings',
		'/secure/admin/ListEventTypes',
		'/plugins/servlet/webhooks',
		'/secure/GHLexoRankManage',
	];
};

export const isSystemNavPath = (baseUrl: String) => {
	const paths = getSystemNavPaths();
	return paths.some((path) => baseUrl.includes(path));
};
