import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { softwareCalendarClassicEmbedRoute } from '@atlassian/jira-router-routes-classic-projects-routes/src/softwareCalendarClassicEmbedRoute.tsx';
import { CalendarEmbedSkeleton } from '@atlassian/jira-skeletons/src/ui/calendar/CalendarSkeleton.tsx';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { softwareCalendarRouteEntry } from './ui/software/calendar/index.tsx';

export const softwareCalendarClassicEmbedRouteEntry = createEntry(
	softwareCalendarClassicEmbedRoute,
	() => ({
		group: 'classic-software',
		skeleton: CalendarEmbedSkeleton,
		component: CalendarEmbedSkeleton,

		entryPoint() {
			if (__SERVER__) {
				return undefined;
			}
			return softwareCalendarRouteEntry;
		},

		layout: chromelessLayout,
		resources: [
			// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
			incidentExperimentTraitResource,
			projectContextResource,
			forgeProjectModuleResource,
			staticSidebarShortcutsResource,
			staticProjectSidebarNavigationResource,
			staticProjectSidebarConnectAddonsNavigationResource,
			// end of resources expanded from getNavigationSidebarProjectResource
			themePreferenceResource,
		],
		ufoName: 'classic-calendar-embed',
		EXPERIMENTAL__shouldReload: fg('jira_fix_calendar_suspending_when_switching_months')
			? () => false
			: undefined,
	}),
);
